import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';
import { COUNTRY_BASE_FRAGMENT } from '@/graphql/_Fragments/Country/Base';
import {
  COMMUNITY_USER_CONNECTION_FULL_FRAGMENT,
} from '@/graphql/_Fragments/CommunityUserConnection/Full';
import { COMMUNITY_USER_URL_BASE_FRAGMENT } from '@/graphql/_Fragments/CommunityUserUrl/Base';

export const COMMUNITY_USER_EP_USER_DETAIL_FRAGMENT = `
  fragment communityUserEpUserDetailFragment on CommunityUser {
    uid
    schemaCode
    firstName
    lastName
    suffix
    prefix
    email
    jobTitle
    employerName
    profilePrivacyMode
    pictureFileResource {
      ...fileResourceBaseFragment
    }
    companyRoles(first: 1, filter: { state: "ACCEPTED", defaultCompany: true }) {
      company {
        uid
        name
      }
    }
    address {
      city
      state
      country {
        ...countryBaseFragment
      }
    }
    urls {
      ...communityUserUrlBaseFragment
    }
    _ourConnection(myUid: "%authUser%") {
      ...communityUserConnectionFullFragment
    }
    _isRecommendedForMe
  }
  ${COMMUNITY_USER_CONNECTION_FULL_FRAGMENT}
  ${COMMUNITY_USER_URL_BASE_FRAGMENT}
  ${FILE_RESOURCE_BASE_FRAGMENT}
  ${COUNTRY_BASE_FRAGMENT}
`;
